<template>
    <div class='not_found'>
        <h1>Uh oh...</h1>
        <p>Did you break this or did I?</p>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
    data () {
        return {

        }
    }
}
</script>

<style scoped>

</style>