<template>
    <section class='projects'>
        <h1>Coming Soon...</h1>
    </section>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>

</style>